<template>
  <v-card :height="210">
    <v-card-title class="mb-0 pb-1">
      Meter Token Information
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            sm="4"
            md="3"
            cols="12"
            class="mb-4"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Token
              </h4>
              <h4 class="font-weight-medium">
                {{ meterToken.token }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Units
              </h4>
              <h4 class="font-weight-medium">
                {{ meterToken.units }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Amount paid
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ meterToken.mpesa_transaction.TransAmount | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Service fee
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ meterToken.service_fee | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            v-if="meterToken.connection_fee_deducted > 0"
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Connection fee deducted
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ meterToken.connection_fee_deducted | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Date purchased
              </h4>
              <h4 class="font-weight-medium">
                {{ meterToken.created_at | formatDate }}
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    meterToken: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>

</style>
