<template>
  <div class="transactions">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <meter-token-details-card
      :meter-token="details"
      :is-loading="isLoading"
    />
    <br>
    <transaction-details-card
      :transaction="details.mpesa_transaction"
      :is-loading="isLoading"
    />
    <br>
    <meter-details-card
      :meter="details.meter"
      :is-loading="isLoading"
    />
    <br>
    <user-details-card
      :user="details.user"
      :is-loading="isLoading"
    />
  </div>
</template>
<script>
import axios from 'axios'
import UserDetailsCard from '@/components/details-card/UserDetailsCard.vue'
import MeterDetailsCard from '@/components/details-card/MeterDetailsCard.vue'
import MeterTokenDetailsCard from '@/components/details-card/MeterTokenDetailsCard.vue'
import TransactionDetailsCard from '@/components/details-card/TransactionDetailsCard.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    UserDetailsCard,
    MeterDetailsCard,
    MeterTokenDetailsCard,
    TransactionDetailsCard,
  },
  data() {
    return {
      search: '',
      details: { user: {}, meter: {}, mpesa_transaction: {} },
      isLoading: false,
      meterReadingId: this.$route.params.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Meter Tokens',
          disabled: false,
          to: { name: 'meter-tokens' },
        },
      ],
    }
  },
  mounted() {
    this.getDetails()
    if (this.meterReadingId) {
      this.breadcrumbs.push(
        {
          text: this.meterReadingId,
          disabled: true,
          to: { name: 'meter-readings-details' },
        },
      )
    }
  },
  methods: {
    getDetails() {
      this.isLoading = true
      axios
        .get(`meter-tokens/${this.meterReadingId}`)
        .then(response => {
          this.details = response.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
